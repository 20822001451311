define(['app'], function(app) {

  var professionalPricingBanner = function() {
    var component = this;

    var _config = {
      selectors: {
        closeBannerButton: '[data-close]'
      },
      classes: {
        showProfessionalPricing: 'professionalPricingBanner-show'
      }
    };

    var _init = function(element) {
      component.element = element;
      component.closeBannerButton = component.element.querySelector(component.config.selectors.closeBannerButton);

      component.bind();
      return component;
    };

    var _bind = function() {
      if (component.closeBannerButton) {
        component.closeBannerButton.addEventListener('click', component.hideBanner);
      }
    };

    var _hideBanner = function() {
      app.element.removeClass(component.config.classes.showProfessionalPricing, component.element);
    };

    component.config = _config;
    component.init = _init;
    component.bind = _bind;
    component.hideBanner = _hideBanner;

    return component;
  };

  return professionalPricingBanner;
});

